.active-container {
  display: block !important;
}

.full-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.show-mobile {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .show-mobile {
    display: block;
  }
  .show-mobile > .rtf {
    margin: 0 0.5rem !important;
    bottom: 4.25rem !important;
  }
  .rtf.open .rtf--mb > * {
    transform: unset !important;
  }
}

@media only screen and (max-width: 850px) {
  .show-mobile > .rtf {
    bottom: 1.25rem !important;
    right: 0.25rem !important;
  }
}

@media only screen and (max-height: 420px) and (orientation: landscape) {
  .show-mobile > .rtf {
    bottom: 1.25rem !important;
    right: 1.25rem !important;
  }
}
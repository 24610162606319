html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#viewCanvas {
  margin: 0 auto;
  width: 2048px;
  height: 376px;
  padding: 0;
  position: relative;
  /*border: 1px solid black;*/
}

.canvas-container {
  margin: 0 auto;
  /*-webkit-box-shadow: 0px 8px 12px 8px rgba(0, 0, 0, 0.1),
    0px 4px 6px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 8px 12px 8px rgba(0, 0, 0, 0.1),
    0px 4px 6px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 12px 8px rgba(0, 0, 0, 0.1),
    0px 4px 6px 2px rgba(0, 0, 0, 0.3);*/
}

#canvasContainer {
  position: relative;
  max-width: 2048px;
  margin: 0 auto;
}

#canvasContainer:fullscreen {
  background-color: #4e4c4c;
  max-width: 4266px !important;
}

#canvasContainer:fullscreen .canvas-container {
  background-color: white;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px opx 0px 0px rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  margin: 0;
  opacity: 1;
}

#canvasContainer:fullscreen #fsButton,
#canvasContainer:fullscreen #timestamp,
#canvasContainer:fullscreen #titleDescription {
  display: none;
}

#canvasContainer:fullscreen #fsLogo {
  display: block;
  /* float: left;
  width: 5rem;*/
}

.hiddenCanvas {
  display: none;
}

.firebaseui-idp-button {
  /*width: 30vw !important;*/
  width: 508px !important;
  max-width: 508px !important;
  /*min-width: 250px !important;*/
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #cccccc !important;
}

.firebaseui-idp-button:hover {
  background-color: #707070 !important;
}
.firebaseui-idp-text {
  color: #cccccc !important;
  text-transform: uppercase !important;
}

#firebaseui_container,
.firebaseui-container,
.firebaseui-card-content {
  /*width: 40vw !important;*/
  width: 508px !important;
  max-width: 508px !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: black !important;
}

.firebaseui-card-header {
  padding: 0 !important;
  display: none !important;
}

#ui-sign-in-email-input {
  color: white;
  border-color: #dedede;
}

.firebaseui-label {
  color: white !important;
}

.firebaseui-card-content {
  color: white !important;
}

.firebaseui-text {
  color: white !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #e40914 !important;
}

.mdl-button--primary.mdl-button--primary {
  color: #e40914 !important;
}

.mdl-button--raised.mdl-button--colored {
  background: #e40914 !important;
  color: #fff;
}

:root {
  --toastify-color-progress-light: linear-gradient(
    to right,
    #408b00,
    #ffffff
  ) !important;

  --toastify-color-progress-warning: linear-gradient(
    to right,
    #e40914,
    #ffffff
  ) !important;

  --toastify-color-progress-error: linear-gradient(
    to right,
    #e40914,
    #ffffff
  ) !important;
}
